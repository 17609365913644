/*
 * @Author: jinqingquan 2842427165@qq.com
 * @Date: 2023-09-21 17:18:53
 * @LastEditors: jinqingquan 2842427165@qq.com
 * @LastEditTime: 2023-10-27 11:31:47
 * @FilePath: \xjd_h5\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  // mode:'history',
  routes: [
    //   {
    //   path: '/index',
    //   name: 'index',
    //   component: () => import('../pages/index'),
    //   meta:{
    //     keepAlive:false,
    //     title:'首页'
    //   }
    // },
    {
      path: '/trafficDetail',
      name: 'trafficDetail',
      component: () => import('../view/traffic/traffic'),
      meta: {
        keepAlive: false,
        title: '交通路线'
      }
    },
    {
      path: '/fineView',
      name: 'fineView',
      component: () => import('../view/fineView/FineView'),
      meta: {
        keepAlive: false,
        title: '美图美景'
      }
    },
    {
      path: '/weather',
      name: 'weather',
      component: () => import('../view/dataAnalysis/weather/weather'),
      meta: {
        keepAlive: false,
        title: '实时天气'
      }
    },
    {
      path: '/dataAnalysisDetail',
      name: 'dataAnalysisDetail',
      component: () => import('../view/dataAnalysis/dataAnalysisDetail/dataAnalysisDetail'),
      meta: {
        keepAlive: false,
        title: '廊氡客流'
      }
    },
    {
      path: '/News',
      name: 'News',
      component: () => import('../view/News/News'),
      meta: {
        keepAlive: false,
        title: '资讯'
      }
    },
    {
      path: '/Map',
      name: 'Map',
      component: () => import('../view/map/Map'),
      meta: {
        keepAlive: false,
        title: '地图'
      }
    },
    {
      path: '/NewsDatail',
      name: 'NewsDatail',
      component: () => import('../view/News/NewsDatail'),
      meta: {
        keepAlive: false,
        title: '资讯详情'
      }
    },
    {
      path: '/introduction',
      name: 'introduction',
      component: () => import('../view/introduction/introduction'),
      meta: {
        keepAlive: false,
        title: '介绍'
      }
    },
    {
      path: '/buyTicket',
      name: 'buyTicket',
      component: () => import('../view/buyTicket/buyTicket'),
      meta: {
        keepAlive: false,
        title: '在线订票'
      }
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('../view/buyTicket/success'),
      meta: {
        keepAlive: false,
        title: '订票成功'
      }
    },
    {
      path: '/theGreatView',
      name: 'theGreatView',
      component: () => import('../view/theGreatView/theGreatView/TheGreatView'),
      meta: {
        keepAlive: false,
        title: '景点'
      }
    },
    {
      path: '/hotelDetail',
      name: 'hotelDetail',
      component: () => import('../view/theGreatView/hotel/Hotel'),
      meta: {
        keepAlive: false,
        title: '酒店'
      }
    },
    {
      path: '/foodAndBeverages',
      name: 'foodAndBeverages',
      component: () => import('../view/theGreatView/foodAndBeverages/FoodAndBeverages'),
      meta: {
        keepAlive: false,
        title: '餐饮'
      }
    },
    {
      path: '/entertainment',
      name: 'entertainment',
      component: () => import('../view/theGreatView/entertainment/Entertainment'),
      meta: {
        keepAlive: false,
        title: '娱乐'
      }
    },
    {
      path: '/tourGuide',
      name: 'tourGuide',
      component: () => import('../view/tourGuide/tourGuide'),
      meta: {
        keepAlive: false,
        title: '导游导览'
      }
    },
    {
      path: '/ordinaryMap',
      name: 'ordinaryMap',
      component: () => import('../view/map/OrdinaryMap'),
      meta: {
        keepAlive: false,
        title: '地图'
      }
    },
    {
      path: '/entertainmentList',
      name: 'entertainmentList',
      component: () => import('../view/theGreatView/entertainment/EntertainmentList'),
      meta: {
        keepAlive: false,
        title: '娱乐'
      }
    },
    {
      path: '/foodAndBeveragesList',
      name: 'foodAndBeveragesList',
      component: () => import('../view/theGreatView/foodAndBeverages/FoodAndBeveragesList'),
      meta: {
        keepAlive: false,
        title: '餐饮'
      }
    },
    {
      path: '/hotelList',
      name: 'hotelList',
      component: () => import('../view/theGreatView/hotel/HotelList'),
      meta: {
        keepAlive: false,
        title: '酒店'
      }
    },
    {
      path: '/theGreatViewList',
      name: 'theGreatViewList',
      component: () => import('../view/theGreatView/theGreatView/TheGreatViewList'),
      meta: {
        keepAlive: false,
        title: '景点'
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('../view/order/order'),
      meta: {
        keepAlive: false,
        title: '订单'
      }
    },
    {
      path: '/countermandOrder',
      name: 'countermandOrder',
      component: () => import('../view/countermandOrder/countermandOrder'),
      meta: {
        keepAlive: false,
        title: '退票'
      }
    },
    {
      path: '/orderDetail',
      name: 'orderDetail',
      component: () => import('../view/order/orderDetail'),
      meta: {
        keepAlive: false,
        title: '入园凭证'
      }
    },
    {
      path: '/meiCityMap',
      name: 'meiCityMap',
      component: () => import('../view/map/MeiMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/shitangMap',
      name: 'shitangMap',
      component: () => import('../view/map/shitangMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/shatanMap',
      name: 'shatanMap',
      component: () => import('../view/map/shatanMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    // 后弄
    {
      path: '/hounongMap',
      name: 'hounongMap',
      component: () => import('../view/map/hounongMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    // 永福
    {
      path: '/yongfuMap',
      name: 'yongfuMap',
      component: () => import('../view/map/yongfuMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/meiyuanMap',
      name: 'meiyuanMap',
      component: () => import('../view/map/meiyuanMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/searchDetails',
      name: 'searchDetails',
      component: () => import('../view/map/searchDetails'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    //科同
    {
      path: '/ketongMap',
      name: 'ketongMap',
      component: () => import('../view/map/ketongMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/ketongSearchDetail',
      name: 'ketongSearchDetail',
      component: () => import('../view/map/ketongSearchDetail'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    // 外漕
    {
      path: '/waicaoMap',
      name: 'waicaoMap',
      component: () => import('../view/map/waicaoMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/ankeMap',
      name: 'ankeMap',
      component: () => import('../view/map/ankeMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/anxiMap',
      name: 'anxiMap',
      component: () => import('../view/map/anxiMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/haiChaoMap',
      name: 'haiChaoMap',
      component: () => import('../view/map/haiChaoMap'),
      meta: {
        keepAlive: false,
        title: '旅游导览'
      }
    },
    {
      path: '/wanxingMap',
      name: 'wanxingMap',
      component: () => import('../view/map/wanxingMap'),
      meta: {
        keepAlive: false,
        title: '导游导览'
      }
    },
    {
      path: '/wanxingSearchDetail',
      name: 'wanxingSearchDetail',
      component: () => import('../view/map/wanxingSearchDetail'),
      meta: {
        keepAlive: false,
        title: '导游导览'
      }
    },
    {
      path: '/haiChaoNewMap',
      name: 'haiChaoNewMap',
      component: () => import('../view/map/haiChaoNewMap'),
      meta: {
        keepAlive: false,
        title: '生活圈'
      }
    },
    {
      path: '/meiCityOridaryMap',
      name: 'meiCityOridaryMap',
      component: () => import('../view/map/meiCityOridaryMap'),
      meta: {
        keepAlive: false,
        title: '地图'
      }
    },
    {
      path: '/meiCityViewDetail',
      name: 'meiCityViewDetail',
      component: () => import('../view/theGreatView/meiCityView/meiCityViewDetail'),
      meta: {
        keepAlive: false,
        title: '梅城景点详情'
      }
    },
    {
      path: '/vrWholeView',
      name: 'vrWholeView',
      component: () => import('../view/map/VrWholeView'),
      meta: {
        keepAlive: false,
        title: 'VR全景游'
      }
    },
    {
      path: '/upload',
      name: 'upload',
      component: () => import('../view/upload/index'),
      meta: {
        keepAlive: false,
        title: '上传文件'
      }
    },
    {
      path: '/refund',
      name: 'refund',
      component: () => import('../view/refund/index'),
      meta: {
        keepAlive: false,
        title: '退款申请'
      }
    },
    {
      path: '/vrMap',
      name: 'vrMap',
      component: () => import('../view/vrMap/index'),
      meta: {
        keepAlive: false,
        title: 'vr导览'
      }
    }
  ]
});
router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});
export default router