/*
 * @Author: jinqingquan 2842427165@qq.com
 * @Date: 2023-06-13 10:03:54
 * @LastEditors: jinqingquan 2842427165@qq.com
 * @LastEditTime: 2023-06-30 14:46:28
 * @FilePath: \xjd_h5\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.js'
import store from './store'
import api from './api/index'
import router from './router'
// 高德
import VueAMap from 'vue-amap';
 // 引入qs
 import Qs from 'qs';
 import { Button, Popup, Picker } from 'vant';
 import axios from 'axios'
 
Vue.use(VueAMap);
Vue.use(Button).use(Popup).use(Picker);
Vue.prototype.$ajax = axios;
Vue.config.productionTip = false;
Vue.prototype.$qs = Qs;
// api共用接口js
Vue.prototype.$api = api;

// VueAMap.initAMapApiLoader({
//   key: '09ba3397bed53916f7419b24ae4a9707',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
//   uiVersion: '1.0.11', // 版本号
//   v: '1.4.4'
// });


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
